body {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

* {
  font-family: 'Vazirmatn';
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}