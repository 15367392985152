@import 'base';
@import url(https://fonts.google.com/specimen/Poppins);

.App {
    box-sizing: border-box;
    background: url('../images/bg-intro-desktop.png') no-repeat center center / cover;
    background-color: $Red;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;
    width: 100%;

    display: flex;

    padding-top: 20px;
    padding-bottom: 20px;

    * {
        font-family: 'Space Mono', monospace;
        font-family: 'Vazirmatn', sans-serif;
        transition: 0.5s;
    }

    .signup-cont {
        * {
            padding: 5px 20px 5px 20px;
        }

        flex-grow: 1;

        display: grid;
        gap: 25 px;
        grid-template-rows: auto auto;
        align-items: center;
        justify-items: center;
        align-content: center;

        padding-bottom: 50px;

        .add {
            background-color: $Blue;
            box-shadow: 0 10px 5px rgba(0, 0, 0, 0.4);
            padding: 10px;
            border-radius: 20px;
            color: white;
            display: flex;
            justify-content: center;
            font-size: 25px;
        }
    }
}

.bold {
    font-weight: bold;
}


@media (max-width: 720px) {

    .App {
        .signup-cont {
            .add {
                padding: 5px;
                border-radius: 20px;
                font-size: 14px;
            }
        }
    }


}
