// Primary

$Red: hsl(0, 100%, 74%);
$Green: hsl(154, 59%, 51%);

// Accent

$Blue: hsl(248, 32%, 49%);

// ### Neutral

$Dark-blue: hsl(249, 10%, 26%);
$Grayish-blue: hsl(246, 25%, 77%);