@import 'base';

.signup-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #fff;
    box-shadow: 0 10px 5px rgba(0, 0, 0, 0.4);

    padding-bottom: 10px;
    border-radius: 15px;

    #Ekht {
        color: rgb(0, 0, 189);
    }

    .inputCont {
        position: relative;
        align-self: center;
        justify-self: center;
        margin: 0;
        gap: 1px;

        display: flex;
        flex-direction: column;

        .Feran {
            margin: 15px 0px 10px 0px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            font-weight: bold;

            .radio {
                height: 18px;
                width: 18px;
            }
        }

        .label {
            height: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center; 
            gap: 10px;
            width: 600px;

            font-size: 22px;
            font-weight: bold;

            transition: ease 0.5s;

            p {
                font-size: 18px;
                padding: 0 4px 0 4px !important;
                border: 1px dotted rgb(5, 147, 2);
            }
        }

        .label.hide {
            margin: 0;
            padding: 0;
            height: 0;
            visibility: hidden;
        }

        .label.show {
            height: 30px;
            visibility: visible;
        }

        .inputtext {
            width: 125px;
            font-size: 18px;
            font-weight: bold;
            color: crimson;
        }

        input {
            height: 30px;
            padding: 2px;
            text-align: center;
            border: $Grayish-blue 2px solid;
            border-radius: 5px;
        }

    }

    button {
        background-color: $Green;
        color: white;
        font-size: 20px;
        text-transform: uppercase;
        display: inline-block;

        padding: 10px 20px;
        margin: 15px 0px 15px 0px;

        border: none;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        transition: transform 0.3s;

        justify-self: center;
        width: 200px;
        height: 50px;
    }

    button:hover {
        transform: translateY(-2px);
        background-color: #00d785;
    }

    button:active {
        background-color: #41ffb6;
        transform: translateY(2px);
    }

    .sum {
        text-align: center;
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: bold;
    }

}


@media (max-width: 720px) {

    .signup-field {

        padding-bottom: 10px;
        border-radius: 15px;

        .inputCont {
            * {
                margin: 0 !important;
                padding: 0 !important;
            }

            .Feran {
                display: flex;
                gap: 10px;
                font-size: 14px;

                .radio {
                    height: 14px;
                    width: 14px;
                }
            }

            .label {
                width: 340px;
                height: 20px;
                gap: 7px;
                font-size: 13px;
                margin-bottom: 5px;
                p {
                    font-size: 12px;
                }
            }

            .label.hide {
                margin: 0;
                padding: 0;
                height: 0;
                visibility: hidden;
            }
    
            .label.show {
                height: 20px;
            }

            .inputtext {
                height: 16px;
                width: 80px;
                font-size: 12px;
            }

            input {
                height: 18px;
            }

        }

        button {
            font-size: 14px;

            padding: 5px 5px;
            margin: 5px 0px 5px 0px;

            width: 150px;
            height: 25px;
        }
        
        .sum {
            height: 25px;
            font-size: 14px;
            margin-bottom: 2px;
        }

    }

}